// import { DateTime } from "ionic-angular";

export class Site {
    siteID?:number;
    reportID?: number;
    jobID?:number;
    jobNumber?:string;
    siteName?:string;
    locationNumber?:number;
    address?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    latitude?:string;
    longitude?:string;
    type?:string;
    status?:string;
    closeoutStatus?:string;   
    notes?: any[];
    photos?: any[];
    reportQuestions?: any[];
     stats?: any;
    phase?: any;
    reportType?: string;
    reportTemplateID?: string;
    reportName?: string;
    scheduleDate?: Date;
    comments?: string;
    lanID?: string;
    crewLead?: boolean;
    supervisor?: boolean;
    signature?: any;
    reportDate?: Date;
    createdByID?: number;
    createdDate?: Date;
    updatedByID?: number;
    updatedDate?: Date;
    numberOfQuestions?: number;
    order?: string;
    location?: number;
    reports?:any[];
    comments1?: string;
    comments2?: string;
    comments3?: string;
    comments4?: string;
}
 