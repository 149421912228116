import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class DatesFormatService {

sqlToJsDate(sqlDate) {
    if (!sqlDate) return;
    let sqlDateArr1: any = sqlDate.split("-");
    let sYear: number = sqlDateArr1[0];
    let sMonth: string = (Number(sqlDateArr1[1]) - 1).toString();
    let sqlDateArr2: any = sqlDateArr1[2].split("T");
    let sDay: number = sqlDateArr2[0];
    let sqlDateArr3: any = sqlDateArr2[1].split(":");
    let sHour: number = sqlDateArr3[0];
    let sMinute: number = sqlDateArr3[1];
    let sqlDateArr4: any = sqlDateArr3[2].split(".");
    let sSecond: number = sqlDateArr4[0];
    let sMillisecond: number = sqlDateArr4[1];

    return new Date(sYear, parseInt(sMonth), sDay, sHour, sMinute, sSecond);
}

formatJsToSqlDate(jsDate: Date) {
  var dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  var d = jsDate.toLocaleDateString('en-US', dateOptions)
  var t = jsDate.toLocaleTimeString('en-US', timeOptions)
  var retval = d + ' ' + t
  return retval;
}

dateShortPipe(originalDate){
  const [datePart, timePart] = originalDate.split(', ');
  const [day, month, year] = datePart.split('/');
  const dateObject = new Date(year, month - 1, day);
  const [hours, minutes, seconds] = timePart.split(':');
  dateObject.setHours(hours, minutes, seconds);

  // Convert the date object to a string in US date format
  const usDateFormat = dateObject.toLocaleDateString('en-US') + ', ' + dateObject.toLocaleTimeString('en-US');
  console.log(usDateFormat);
  return usDateFormat;
}

// dateShortPipe(originalDate){
//     const [datePart, timePart] = originalDate.split(', ');
//     const [day, month, year] = datePart.split('/');
//     const [hours, minutes, seconds] = timePart.split(':');
//     const processedDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

//     // Format the date using Intl.DateTimeFormatOptions
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//       hour: 'numeric',
//       minute: 'numeric',
//       second: 'numeric',
//       hour12: true,
//     };

//     let formattedDate = new Intl.DateTimeFormat('en-US', options).format(processedDate);
//     return formattedDate
// }




}