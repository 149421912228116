import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeParserService {

  parseTimeStringToTimeObject(date, timeString: string) {
    // Regular expression to match the time format
    const timeRegex = /^(\d+):(\d+)\s+(AM|PM)$/i;
    const match = timeString.match(timeRegex);

    if (!match) {
      // Invalid time format
      return null;
    }

    let hours = parseInt(match[1], 10);
    let minutes = parseInt(match[2], 10);
    let period = match[3].toUpperCase();

    if (hours < 1 || hours > 12 || minutes < 0 || minutes > 59) {
      // Invalid hours or minutes
      return null;
    }

    // Adjust hours for PM
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    const currentDate = new Date(date);
    currentDate.setHours(hours, minutes, 0, 0);

    return currentDate.toLocaleString("PST");
  }
}
