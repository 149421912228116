import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Network } from '@capacitor/network';
import { IdbService } from '../indexDB/idb.service';
import { Task } from 'src/app/models/Task';
 

@Injectable({
  providedIn: 'root'
})
export class UpdateQueueService {

  networkState: any;
  runningQueueUpdate = false;
  runningQueueCycles = 0;

  constructor(
    public http: HttpClient,
    private idb: IdbService,

  ) {
    this.onAppOnline();
    console.log('update-queue constructor')

  }

  async init() {
    console.log('init queues tasks');
    //this.networkState = this.network.type;
    this.networkState = Network.getStatus()
    console.log('network state => ', this.networkState);

    //if (this.networkState !== this.network.Connection.NONE) {
    if (!this.networkState.connected) {
      console.log('first run of queue')
      this.runQueue();
    }
    // this was giving me an error
    // this.network.onChange().subscribe(() => {
    //   this.network.onConnect().subscribe(() => {
    //     console.log('network connected!, running queue');
    //     this.runQueue();
    //   })
    // });


  }
  onAppOnline() {
    window.addEventListener('online', () => {
      this.runQueue();
    });
  }

  addToQueue(taskObj: Task, typeObject?) {

    let task = new Task(taskObj);

    /*TODO: none of this logic should be in here */
    if (typeObject === 'pdfDynamicReport') {
      console.log('typeObject => ', typeObject);
      task.taskName = task.payload.filter(report => report.fieldName === "ReportName").map(res => { return res.value }).toString();
      // taskQueueID = taskData.filter(report => report.fieldName === "ReferenceCode").map(res => { return res.value }).toString();
      // taskDate = taskData.filter(report => report.fieldName === "ReportDate").map(res => { return res.value }).toString();
      task.typeObject = typeObject;
      task.payload = task.payload.questions;
    }

    this.addToIdbQueue(task);
    return

  }

  interactWithDB(task): Observable<any> {
    console.log('interactWithDB => ', task)
    switch (task.method) {
      case 'GET':
        return this.http.get(task.url);
      case 'POST':
        return this.http.post(task.url, task.payload, { observe: 'response' });
      case 'PUT':
        return this.http.put(task.url, task.payload);
      case 'DELETE':
        return this.http.delete(task.url);
    }
  }


  async addToIdbQueue(task) {
    await this.idb.insert('updateQueue', task);
    console.log("Inserted New Queue Task", task);
    this.runQueue();
  }

  async runQueue() {

    // if something happened to screw it up, we only allow for 5 cycles to be skipped and 
    // then we assume it's just crashed and needs to be restarted.
    if (this.runningQueueCycles > 5) {
      this.runningQueueUpdate = false;
      this.runningQueueCycles = 0;
    }
    if (!this.runningQueueUpdate) {
      this.runningQueueUpdate = true;
      let task: any = await this.nextQueueItem()
      if (!task) task = { queueID: -1 };
      if (task.queueID > 0) {
        console.log('running an update task')
        try {
          let cmd = this.interactWithDB(task).toPromise();
          
          let retval = await cmd;
          await this.idb.simpleDelete('updateQueue', task.queueID);
          this.runningQueueUpdate = false;
          console.log('sent the update successfully', retval, task)
          this.runQueue().then();
        } catch (error) {
          console.error('fuck, that didn\'t work at all!', error, task)
          task.counterTries++;
          await this.idb.update('updateQueue', task.queueID, task);
          this.runningQueueUpdate = false;
        }
      } else {
        //console.log('no update tasks to run');
        this.runningQueueUpdate = false;
      }
    } else {
      this.runningQueueCycles++
    }
  }

  async nextQueueItem(): Promise<any> {


    let table = 'updateQueue';
    var retval = { queueID: 0, counterTries: 0 };

    return new Promise((resolve, reject) => {
      var transaction = this.idb.db.transaction(table, "readwrite");
      transaction.oncomplete = () => resolve(retval);
      transaction.onerror = () => reject(null);
      var objectStore = transaction.objectStore(table);
      var request = objectStore.openCursor(null, 'prev');
      request.onsuccess = (event) => {
        var queueID = 0;
        var closeIt = false;
        var cursor = event.target.result;
        if (cursor) {
          if (queueID == 0) {
            if (!cursor.value.counterTries) {
              cursor.value.counterTries = 0;
              cursor.update(cursor.value);
            }
            if (cursor.value.counterTries < 3) {
              retval = cursor.value;
              queueID = retval.queueID;
            }            
          }
          cursor.continue();
        }

      }
    });

  }




}