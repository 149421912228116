export class SlackMessage {
    text?: string;
    blocks?: SlackBlock[];
    constructor(text: string = "") {
        this.blocks = [];
    }

}

export class SlackBlock {
    type: string = "section";
    text?: { type: string, text: string }
    constructor(message: string = "") {
        if (message) {
            this.text = {
                "type": "mrkdwn",
                "text": message
            }
        }

    }

}
// {
//     "text": "Dash Error Log",
//     "blocks": [
//         {
//             "type": "section",
//             "text": {
//                 "type": "mrkdwn",
//                 "text": "loading client:24"
//             }
//         },
//         {
//             "type": "section",
//             "text": {
//                 "type": "mrkdwn",
//                 "text": "<http://localhost:4200/reviews/customers/24|http://localhost:4200/reviews/customers/24>"
//             }
//         },
//         {
//             "type": "section",
//             "fields": [
//                 {
//                     "type": "mrkdwn",
//                     "text": "*Details*\nnone"
//                 }
//             ]
//         }
//     ]
// }