import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardService } from './guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
   },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
   },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate:[GuardService]
   },
  {
    path: 'fleet-list',
    loadChildren: () => import('./pages/fleet/fleet-list/fleet-list.module').then( m => m.FleetListPageModule)
  },
  {
    path: 'ts-calendar',
    loadChildren: () => import('./pages/time-sheet/ts-calendar/ts-calendar.module').then( m => m.TsCalendarPageModule),
        canActivate:[GuardService]

  },
  {
    path: 'ts-jobs',
    loadChildren: () => import('./pages/time-sheet/ts-jobs/ts-jobs.module').then( m => m.TsJobsPageModule),
        canActivate:[GuardService]

  },
  {
    path: 'ts-jobs/:id',
    loadChildren: () => import('./pages/time-sheet/ts-jobs/ts-jobs.module').then( m => m.TsJobsPageModule),
    canActivate:[GuardService]

  },
  {
    path: 'ts-show-up',
    loadChildren: () => import('./pages/time-sheet/ts-show-up/ts-show-up.module').then( m => m.TsShowUpPageModule)
  },
  {
    path: 'ts-confirmation',
    loadChildren: () => import('./pages/time-sheet/ts-confirmation/ts-confirmation.module').then( m => m.TsConfirmationPageModule)
  },
  {
    path: 'jobs/new',
    loadChildren: () => import('./pages/er-job/er-job/er-job.module').then( m => m.ErJobPageModule)
  },
  {
    path: 'er-job-completed',
    loadChildren: () => import('./pages/er-job/er-job-completed/er-job-completed.module').then( m => m.ErJobCompletedPageModule)
  },
  {
    path: 'job-list',
    loadChildren: () => import('./pages/jobs-list/jobs-list/jobs-list.module').then( m => m.JobsListPageModule),
    canActivate:[GuardService]
  },
  {
    path: 'jobs/:jobID/job-page',
    loadChildren: () => import('./pages/jobs-list/job-page/job-page.module').then( m => m.JobPagePageModule)
  },
  {
    path: 'job-list/:jobID/job-gallery',
    loadChildren: () => import('./pages/jobs-list/job-gallery/job-gallery.module').then( m => m.JobGalleryPageModule)
  },
  {
    path: 'job-list/:jobID/job-docs',
    loadChildren: () => import('./pages/jobs-list/job-docs/job-docs.module').then( m => m.JobDocsPageModule)
  },
  {
    path: 'jobs/:jobID/job-location/:id',
    loadChildren: () => import('./pages/jobs-list/job-location/job-location.module').then( m => m.JobLocationPageModule)
  },
  {
    path: 'jobs/dynamic-report',
    loadChildren: () => import('./pages/jobs-list/job-reports/dynamic-report/dynamic-report.module').then( m => m.DynamicReportPageModule)
  },
  {
    path: 'jobs/cbilling-report',
    loadChildren: () => import('./pages/jobs-list/job-reports/contractor-billing/contractor-billing.module').then( m => m.ContractorBillingPageModule)
  },
  {
    path: 'jobs/construct-complete',
    loadChildren: () => import('./pages/jobs-list/job-reports/construct-complete/construct-complete/construct-complete.module').then( m => m.ConstructCompletePageModule)
  },
  {
    path: 'jobs/reports-viewer',
    loadChildren: () => import('./pages/jobs-list/job-reports/pdf-reports-viewer/pdf-reports-viewer.module').then( m => m.PdfReportsViewerPageModule)
  },
  {
    path: 'job-docs',
    loadChildren: () => import('./pages/jobs-list/job-docs/job-docs.module').then( m => m.JobDocsPageModule)
    ,canActivate:[GuardService]
  },
  {
    path: 'docs-list',
    loadChildren: () => import('./pages/documents-list/documents-list.module').then( m => m.DocumentsListPageModule)
    ,canActivate:[GuardService]
  },
  {
    path: 'file-sync',
    loadChildren: () => import('./pages/file-sync/file-sync.module').then( m => m.FileSyncPageModule)
    ,canActivate:[GuardService]
  } ,
  {
    path: 'file-sync/:folderid',
    loadChildren: () => import('./pages/documents-list/documents-list.module').then( m => m.DocumentsListPageModule)
    ,canActivate:[GuardService]
  },
  {
    path: 'queue-list',
    loadChildren: () => import('./pages/queue-list/queue-list.module').then( m => m.QueueListPageModule)
    ,canActivate:[GuardService]
  },
  {
    path: 'device',
    loadChildren: () => import('./device/device.module').then( m => m.DevicePageModule)
    ,canActivate:[GuardService]
  },
  {
    path: 'ts-explanations',
    loadChildren: () => import('./pages/time-sheet/ts-explanations/ts-explanations.module').then( m => m.TsExplanationsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
