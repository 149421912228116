 import { Injectable } from '@angular/core';
import { IonDatetime } from '@ionic/angular';
import { Site } from '../../models/interfaces/Site';
import { Question } from '../../models/interfaces/Question';

export interface IJobListItem {
  jobID: number;
  jobNumber: string;
  address1: string;
  city: string;
  crewFolder: string;
  department: string;
  jobCode: string;
  jobName: string;
  location: string;
  notificationNumber: string;
  postCode: string;
  state: string;
  subcontractor?: any;
}

export interface IReportTemplate {
  reportTemplateID: number;
  reportName: string;  
  questions:number[];
}

export interface IQuestionItem{
  applicable: boolean
  category: string
  ecer: null
  location: number
  questionID: number
  reportID: number
  reportItemID: number
  response: number
  responseBy?: any
  responseDate?: IonDatetime
  status?: any
  summaryText: string
  text: string 
}

export interface IReportItem{
  reportID: number;
  reportItemID: number;
  jobID: number;
  siteID: number; 
  category: string;
  ecer?: any;
  location: number;
  questionID: number;
  response?: number;
  status?: any;
  applicable?: any;
}

@Injectable({
  providedIn: 'root'
})
export class DbService {
  private _reportList: any[] = [];
  private _jobTable: IJobListItem[] = [];
  private _siteTable: Site[] = [];
  private _setupTable: Site[] = [];
  private _questionTable: Question[] = [];
  private _jobPackageTable: any[] = [];
  private _reportTemplateTable: IReportTemplate[] = [];
  private _reportItemTable: IReportTemplate[] = [];
  private _selectedJob: any;
  private _photoTable: any[] = [];
  private _fieldReportTable: any[] = [];
  private _vehicles:any[]=[];
 
  get reportTemplateTable() { return this._reportTemplateTable;  }
  set reportTemplateTable(val) { this._reportTemplateTable = val; }

  get reportItemTable() { return this._reportItemTable;  }
  set reportItemTable(val) { this._reportItemTable = val; }

  get reportList() { return this._reportList;  }
  set reportList(val) { this._reportList = val; }

  get jobTable() { return this._jobTable;  }
  set jobTable(val) { this._jobTable = val; }

  get questionTable() { return this._questionTable;  }
  set questionTable(val) { this._questionTable = val; }

  get jobPackageTable() { return this._jobPackageTable;  }
  set jobPackageTable(val) { this._jobPackageTable = val; }

  get siteTable() { return this._siteTable;  }
  set siteTable(val) { this._siteTable = val;}

  get setupTable() { return this._setupTable;  }
  set setupTable(val) { this._setupTable = val; }
  
  get selectedJob() { return this._selectedJob;  }
  set selectedJob(val) { this._selectedJob = val; }
  
  get photoTable() { return this._photoTable;  }
  set photoTable(val) { this._photoTable = val; }

  get fieldReportTable() { return this._fieldReportTable;  }
  set fieldReportTable(val) { this._fieldReportTable = val; }

  get vehicleTable() { return this._vehicles;  }
  set vehicleTable(val) { this._vehicles = val; }

  
  

  constructor() {
    
  }
}
