import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';

import { DataLayerService } from '../data-layer/data-layer.service';
import { IIdentity, JWT } from 'src/app/models/jwt';

import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/models/interfaces/user';

@Injectable({
  providedIn: 'root',

})
export class AuthService {

  currentUser = new BehaviorSubject(null);
  currentSessionID = new BehaviorSubject(null);
  currentDeviceSerial = new BehaviorSubject(null);
  expiration = new BehaviorSubject(null);


  currentPage: string;
  redictUrl: string;
  signInURL: string;
  env = environment;
  state = environment.authorizationState; //this can be any value, it just has to match on the way back.



  constructor(
    private http: HttpClient,
    private dl: DataLayerService,
    private router: Router,
    private fb: FormBuilder
  ) {

    var hostname = window.location.hostname;
    var port = window.location.port;
    this.currentPage = router.url;
    if (hostname == 'localhost') {

      this.redictUrl = `http://${hostname}:${port}/login`;
      this.signInURL = `http://${hostname}:${port}/login`;

    } else {

      this.redictUrl = 'https://' + hostname + '/login';
      this.signInURL = 'https://' + hostname + '/login';

    }

  }

  signIn(){
    if(this.dl.online){
      this.signInOnline();
    } else {
      console.log('we are offline, getting data from local');
      this.router.navigate(['/dashboard']);
    }
  }

  signInOnline() {
    {
      let nonce = this.generateNonce(20);
      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create element to open OAuth 2.0 endpoint in new window.
      var form = document.createElement('form');
      form.setAttribute('method', 'GET'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);
      // sample scope: https://www.googleapis.com/auth/drive.metadata.readonly,
      // Parameters to pass to OAuth 2.0 endpoint.
      var params: any = {
        'client_id': environment.authorizationClient,
        'redirect_uri': this.redictUrl,
        'scope': 'openid email profile',
        'state': environment.authorizationState,
        'include_granted_scopes': 'true',
        'response_type': 'id_token token',
        'hd': 'pinnaclepowersvcs.com',
        'nonce': nonce
      };

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    }
  }

  generateNonce(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  storeUser(user) {
    this.currentUser.next(user);
    let strUser = JSON.stringify(user);
    localStorage.setItem('user', strUser)
  }

  getUser() {
    let user = this.currentUser.value;
    if(user) return user;

    let strUser = localStorage.getItem('user')
    if (strUser != null) {
      try {
        let user = JSON.parse(strUser);
        this.currentUser.next(user);
        return user;
      } catch (e) {
        console.warn("ERROR WITH THE USER PARSING", e)
      }
    } else {
      return null;
    }
  }

  async setLocalSerial(deviceSerial, idToken) {

    this.currentDeviceSerial.next(deviceSerial);
    localStorage.setItem('deviceSerial', deviceSerial);

    this.dl.setSerialInDB(deviceSerial, idToken).subscribe(res => {
      console.log('device set in db => ', res);
    })

  }

  getLocalSerial() {
    const deviceSerial = localStorage.getItem('deviceSerial');
    if (deviceSerial != null) {
      this.currentDeviceSerial.next(deviceSerial);
    }
    return deviceSerial;
  }

  getLocalSessionID() {
    const localSessionID = localStorage.getItem('currentSessionID');
    if (localSessionID != null) {
      this.currentSessionID.next(localSessionID);
      console.log("localSessionID => ", localSessionID)
      return localSessionID;
    } else {
      return null;
    }
  }

  async getSessionId(user, token: string) {
    console.log("getting session id.... ", user)
    if (!user) return;
    let url = this.dl.apiServer + '/api/session/?sid=sid';
    let deviceSerial = localStorage.getItem('deviceSerial');
    if (`${deviceSerial}`.length < 10) {
      deviceSerial = token.substring(0, 100);
      deviceSerial = new Date().getTime().toString() + '@@' + deviceSerial;
    } else {
      deviceSerial = deviceSerial.substring(0, 100);
    }

    this.setLocalSerial(deviceSerial, token);
    //let coords = { latitude: 0, longitude: 0, accuracy: 0, altitudeAccuracy: 0, altitude: 0, speed: 0, heading: 0 }
    let coords;
    await Geolocation.getCurrentPosition()
    .then((resp) => {
      coords = resp.coords;
      console.log("coords => ", coords)
    }).catch((error) => {
      console.log('Error getting location', JSON.stringify(error));
    });

    let userToLog = {
      "email": user.email,
      "token": token,
      "device": {
        "model": null,
        "platform": null,
        "uuid": null,
        "version": null,
        "manufacturer": null,
        "isVirtual": null,
        "serial": deviceSerial
      },
      "gps": coords,
      "password": ""
    }

    return await this.http.post(url, userToLog)
      .subscribe((res: any) => {
        console.log("user sessionID => ", res);
        let sessionID = res.sessionId;
        this.dl.setUserData(res);
        this.dl.setSessionID(sessionID);
        this.currentSessionID.next(sessionID);
        localStorage.setItem('currentSessionID', sessionID);

      }, error => {
        console.log('some error happens getting the sessionID, back to login...', error);
        this.router.navigate(['/login']);
      });
  }

  async userInfoFromAccessToken(accessToken: string) {
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    let json = await response.json()
    console.log('json', json);
    return json;

  }

  async processLogin(hashString:string, authorizationState: string) {
    console.log('directFromGoogle');
    
    // parse hash string to extract oAuth params.
    var params: any = {};
    var regex = /([^&=]+)=([^&]*)/g, m;
    while (m = regex.exec(hashString)) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    if (Object.keys(params).length > 0) {
      if (params['state'] && params['state'] == authorizationState) {

        let expireTime = new Date().getTime() + (params.expires_in * 1000);
        let userInfo = await this.getUserInfo(params.id_token);

        localStorage.setItem('expires_at', params.expires_in)
        localStorage.setItem('access_token', params.access_token)
        localStorage.setItem('expire_time', expireTime.toString());
        
        // this setItem is duplicated
        //localStorage.setItem('expires_at', expireTime.toString());
        
        localStorage.setItem('oauth2', JSON.stringify(params));
        //localStorage.setItem('user', JSON.stringify(userInfo));

        this.storeUser(userInfo);
        let jwt: any = this.jwtDecode(params.id_token);        
      
        let fullData:{access:any,identity :IIdentity, approved:boolean} = { access: params, identity: jwt, approved:true }
        return fullData
      }
    }
    let failed = { access: null, identity: null, approved:false }
    return failed;
  }

  async logout() {
    this.currentSessionID.closed;
    localStorage.removeItem('currentSessionID');
    let obj = localStorage.getItem('oauth2');
     this.expiration.next(0);
    if (obj) {
      let params = JSON.parse(obj);
      this.revokeAccess(params.access_token);
    }

    if (obj) {
      let params = JSON.parse(obj);
      const revocationEndpoint = 'https://oauth2.googleapis.com/revoke';
      const payload = `token=${params.access_token}`;
      const headers = { 'Content-Type': 'application/x-www-form-urlencoded', };
      this.http.post(revocationEndpoint, payload, { headers: headers }).pipe(
        tap((response: any) => {
          localStorage.removeItem('oauth2');
        }),
        catchError(error => {
          console.error('Error revoking token:', error);
          localStorage.removeItem('oauth2');
          return of(error);
        })
      ).subscribe();
    }
    this.router.navigate(['/login'])
  }

  revokeAccess(accessToken: string) {
    // Google's OAuth 2.0 endpoint for revoking access tokens.
    var revokeTokenEndpoint = 'https://oauth2.googleapis.com/revoke';

    let myForm = this.fb.group({ token: accessToken })
    this.http.post(revokeTokenEndpoint, JSON.stringify(myForm.value))
      .subscribe(retval => {
        localStorage.removeItem('oauth2');

        //this is changed so we can force the sidepanel to close if the user has the mouse over it.
        // this.router.navigate(["login"],{ skipLocationChange: true });
        setTimeout(() => { window.location.href = '/login'; }, 150);
      });

    return;
  }


  jwtDecode(id_token: string) {
    let jwt = new JWT(id_token);
    return jwt.identity;
  }

  async getUserInfo(id_token: any = null) {
    let userInfo = null;

    if (id_token) {
      userInfo = this.jwtDecode(id_token)
      return userInfo;
    }

    let strAuth = localStorage.getItem('oauth2');
    if (strAuth) {
      let oauth2 = JSON.parse(strAuth);
      userInfo = this.jwtDecode(oauth2.id_token)
      console.log('id_token', id_token)
      return id_token;
    } else {
      return null;
    }
  }



}
