import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-hours-calculator',
  templateUrl: './hours-calculator.component.html',
  styleUrls: ['./hours-calculator.component.scss'],
})
export class HoursCalculatorComponent implements OnInit {

  @Input() valueNum: number;
  @Input() valueInfo: string;
  selectorOptionsNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  hoursSelected = null;
  twoDigits = [];
  addHalf: boolean = false;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if(this.valueNum != undefined || this.valueNum != null){
      this.hoursSelected = this.valueNum
    } else {
      this.hoursSelected = 0;
    }
    console.log("valueInfo => ", this.valueInfo)
  }

  addHours(value){
    //console.log("value calc => ", value);
    this.twoDigits.push(value);
    let twoNums = this.twoDigits.slice(this.twoDigits.length - 2, this.twoDigits.length).join("");
    //console.log(twoNums);
    this.hoursSelected = Number(twoNums);
  }

  toggleHalfHour(){
    if(Number.isInteger(this.hoursSelected) || this.hoursSelected === null){
      this.hoursSelected += .5;
    } else {
      this.hoursSelected -= .5
    }
  }

  clearHours(){
    this.twoDigits = [];
    this.hoursSelected = 0;
  }

  updateHours(){
    this.modalController.dismiss(this.hoursSelected);
  }

  closeModal(){
    this.modalController.dismiss(null);
  }

}
