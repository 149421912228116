import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate{
  constructor(private router: Router, private auth: AuthService, private toastController: ToastController,
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    // if this is a return from Google Authentication, then verify the JWT and 
    // retrieve a session from our server.
    // a return of 'true' will leave us on the login page;
    // we will continue to return true, which leaves us on the login page, until 
    // authentication from both google and our server have completed successfully
    console.log('GuardNext',next)
    console.log('state.url ', state.url)
    if (state.url.includes('/login')) {
      var hashString = `${location.hash}`.replace('#','');

      if (hashString.includes(environment.authorizationState)) {
        
        this.notify('Authentication process has begun')
        this.authenticate(hashString);  //this happens async, so they wait on the login page while it happens
        return true;


      }else{
        console.log('no hash',next, location)
       
      }
    } else {
      //if you have a sessionID, you're good to go.  later we can be restrictive.
      let loggedIn = `${this.auth.currentSessionID.value}`;
      console.log('loggedIn', loggedIn);
      if (loggedIn === '') { this.router.navigateByUrl('/login'); }
    }
  }

  authenticate(hashString) {

    this.auth.processLogin(hashString, environment.authorizationState).then(retval => {
      if (retval.approved) {
        localStorage.setItem('id_token', retval.access.id_token);
        this.notify('Authenticating with Dash Server');
        this.auth.getSessionId(retval.identity.email, retval.access.id_token)
          .then((loginData: any) => {
            this.notify('Autentication Success! ' + loginData);
            //localStorage.setItem('sessionId', loginData.sessionId);
            localStorage.setItem('currentSessionID', loginData.sessionId);
            this.auth.currentSessionID.next(loginData.sessionId);
            setTimeout(() => {
              this.router.navigateByUrl('/dashboard');
            }, 1000);
          },
            err => {
              this.notify('Failed to authenticate with Dash Server')
              return true;
            }
          );
        return true;
      } else {
        this.notify('Google Authentication Failed');

        return true;
      }
    })
  }

  async notify(msg: string) {
    console.log(msg);
    // const toast = await this.toastController.create({
    //   message: 'msg',
    //   duration: 3000
    // });


    // await toast.present();
  }
}

/*
// all other pages except login

   let expire =0;
  let strExpire = localStorage.getItem('expire_time')
  if(strExpire){
    let exp =  localStorage.getItem('expire_time');
    let expireTime = exp ? parseInt(exp) : new Date().getTime();
      let  expire = Math.floor((expireTime - new Date().getTime()) / 1000 / 60);
    console.log('expire_time',expire)
    // this.router.navigateByUrl('/dashboard');

  }    

*/