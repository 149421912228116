export interface IIdentity {
    email: string;
    given_name: string;
    family_name: string;
    hd: string;
    iss: string;
    name: string;
    picture: string;
    exp: number;
    email_verified: boolean;

}

export class JWT {
    valid: boolean = false;
    _raw_token: string = '';
    private decode(token: string): any {
        const base64Url = token.split('.')[1]; // Extract payload part of the JWT
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert base64url to base64
        const binaryString = window.atob(base64); // Decode base64 to binary string

        // Use TextDecoder to handle UTF-8 decoding properly
        const decoder = new TextDecoder('utf-8');
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        const utf8Payload = decoder.decode(uint8Array);
        try {
            var identity = JSON.parse(utf8Payload);
            this._exp = new Date(identity.exp * 1000);
            console.log('new exp', this._exp)
            this.identity = identity;
        } catch {
            this.identity = null;
        }


    }
    private _exp = new Date(); //start expired
    get expiresInMinutes() {
        const differenceInMinutes = (this._exp.getTime() - new Date().getTime()) / (1000 * 60);
        return differenceInMinutes
    }

    identity?: IIdentity | null;
    constructor(private token: string) {
        this._raw_token = token;
        this.decode(token);
        this.valid = this.expiresInMinutes > 0;
    }
}
