/// BaseFile is the model used in the API Server
export class BaseFile{
    id:string;
    name:string;
    mimeType:string;    
    parents?:string[];
    base64?:any;
    referenceCode?: string;
}

export class SimpleFile extends BaseFile{
    rootFolder?:string
    trackedFolderID?:string //this is deprecated
    iconLink?:string;
    modifiedDate?:Date;
    path?:string;
    size?:number;
    tag?:string;
   
    //stuff that's in google system but we probably won't use here
    trashed:boolean;
    permissions?:string;
    parents?:string[];
    children?:string[]
    parentFolderID?:string; //an old id that comes from the DB by default.
  
}

export class SimpleFolder{
    public get size() {
        let s=0;
        this.files.forEach(f =>{
            s=s+f.size;
        }) 
        return s;
    }
    files:SimpleFile[]=[]
    folder:SimpleFile
    status:string;
}