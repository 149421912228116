import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  progress = 0;
  
  constructor() { }

  ngOnInit() {
    const intervalId = setInterval(() => {
      this.progress += 5;
      if (this.progress >= 100) {
        clearInterval(intervalId);
      }
    }, 100);
  }

}
