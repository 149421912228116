import { Injectable } from '@angular/core';
import { PhotoInterface } from '../../models/interfaces/Photo';
import { IdbService } from '../indexDB/idb.service';
import { ActionSheetController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
 
  constructor(private idb: IdbService,
              private actionSheetCtrl: ActionSheetController) {}

  /** saves photo object to photos datastore indexed by referenceCode */
  async savePhoto(photo: PhotoInterface): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        var ref = `${photo.referenceCode}`;  //this is because we use strings for reference codes in the db

        let hasKey = await this.idb.keyExists('photos',ref);
        if (hasKey) {
          await this.idb.update('photos',ref, photo);
        } else {
          await this.idb.insert('photos', photo);
        }
        resolve(true);
      } catch (e) {
        reject(false);
      }
    });
  }

  /** retreive photo from local storage, with failover to server if online */
  async getPhoto(referenceCode: string): Promise<PhotoInterface> {
    var ref = `${referenceCode}`;  //this is because we use strings for reference codes in the db

    return new Promise(async (resolve, reject) => {
     // let hasKey: boolean = await this.idb.keyExists('photos', ref);
      let photo: PhotoInterface = await this.idb.select('photos', ref);

      if (photo) {
       // let photo: Photo = await this.idb.select('photos', ref);
        resolve(photo);
      } else {
        resolve(null);
      }
    })
  }

  /** retrieves an array of photos from local storage with failovers to server if online  */
  async getPhotos(referenceCodes: any[]) {
    return new Promise(async (resolve, reject) => {
      let photos = []
      try{
        
      referenceCodes.forEach(async imageRef => {
        try {
          let photo = await this.getPhoto(imageRef);
          if (photo) {
            photos.push(photo);
          }
        } catch (e) {
          console.error('failed to retrieve image', imageRef);
        }
      });
      resolve(photos);

    }catch(e){}
    }
    );
  }

  /** removes a photo from local storage */
  async deletePhoto(referenceCode) {
    var ref = `${referenceCode}`;  //this is because we use strings for reference codes in the db

    return new Promise(async (resolve, reject) => {
      try {
        await this.idb.delete('photos', ref);
        resolve(true);
      } catch (e) {
        reject(false);
      }
    });

  }




}
