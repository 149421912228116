//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //const deviceSerial = this.auth.currentDeviceSerial.value;

    const deviceSerial = localStorage.getItem('deviceSerial')

    //Authentication by setting header with token value
    if (deviceSerial) {
      request = request.clone({
        setHeaders: {
          'Device': deviceSerial
        }
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    // Check if sid parameter is not present, then retrieve from local storage
    
    if (!request.params.has('sid')) {
      const sid = localStorage.getItem('currentSessionID');
      const oauth = localStorage.getItem('oauth2');
      if (sid) {
        request = request.clone({
          params: request.params.set('sid', sid)
        });
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${oauth}`
          }
        });
        //console.log('Request with SID:', request);
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //  console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(error);
      }));
  }


}