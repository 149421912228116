import { Injectable } from '@angular/core';
import { IdbService } from '../indexDB/idb.service';
import { Command } from '../../models/Command';
import { DataLayerService } from '../data-layer/data-layer.service';
import { interval } from 'rxjs';
 
 

@Injectable({
    providedIn: 'root'
})
export class SyncService {
    lastUpdate;
    logger;
    syncActive = false;
    pauseSync = false;
    JobsToInsert = []
    JobsToUpdate = []
    bulkAttempts: number = 0;
    constructor(private idb: IdbService, 
        private dl: DataLayerService,
        //private updateQueue: UpdateQueueService
        ) 
        {
        this.logger = [];
        }


    init() {
        //Schedule an update every 10 seconds. 
        //Disable if mid-sync or off-line.  
        //Ensure it only gets triggered once.
        const source = interval(10000);
        if (!this.syncActive) {
            this.syncActive = true;
            const subscribe = source.subscribe(val => {
                
                this.dl.checkOnlineNetwork();
                if (!this.pauseSync && this.dl.online && this.dl.sessionID) {
                    this.syncJobStore().then(retval => { 
                        //console.log('sync', retval) 
                    });
                    //console.log('trigger run queue')
                    //this.updateQueue.runQueue()
                }
                //this.dl.netWorkOnline.next(this.dl.online)
            });
        }
    }

 
    
    
    //still not sure if this is the way to go.
    initJobFolderSync() {
        const source = interval(10000);
        if (!this.syncActive) {
            this.syncActive = true;
            const subscribe = source.subscribe(val => {
                if (!this.pauseSync && this.dl.online) {
                    if (this.JobsToInsert.length > 0) {
                        let id = this.JobsToInsert[this.JobsToInsert.length - 1];
                        this.JobsToInsert.pop()
                        this.dl.jobLoader(id).then();

                    }
                }
            });
        }
    }
    
    async syncJobStore() {
        //console.log('===================== syncJobStore ==========================')
        this.pauseSync = true;
        this.syncActive = true;
        var lastCheck = this.lastUpdate;
        var additions = [];
        var updates = [];
        var ed;

        this.bulkAttempts += 1;


        try {


            //if this is the first load, then we're going to look up the last time
            //the data has been loaded, otherwise we'll use the saved date.
            if (!lastCheck) {
                var dateSet = await this.getLastUpdatedByPromise();
                console.log("dateSet res => ", dateSet)
                if (dateSet.responseTime === 0) return false;
                ed = new Date(dateSet.value);

            } else {
                ed = lastCheck;
            }
            this.lastUpdate = new Date();

            let sqlExpireDate: string = ed.toLocaleDateString('en-US') + ' ' + ed.toLocaleTimeString();
            //console.log('cmdJobsMasterUpdateMobile sqlExpireDate => ', sqlExpireDate)

            this.logger.push('retrieving updated data since ' + sqlExpireDate);
            var cmd = new Command();
            cmd.procedure = "cmdJobsMasterUpdateMobile";
            cmd.addParameter("ExpireDate", sqlExpireDate);

            var serverRecords = await this.dl.command(cmd).toPromise();
            this.logger.push('Found ' + serverRecords.length + ' records from server');

            var jobList = await this.getJobIDListByPromise()
            if (!jobList) jobList = [];
            this.logger.push('Found ' + jobList.length + ' records locally');

            serverRecords.forEach((row: any) => {
                let reportIndex = jobList.findIndex(r => r === row.jobID);
                if (reportIndex < 0) {
                    additions.push(row);
                } else {
                    updates.push(row);
                }
            });

            this.logger.push('inserting ' + additions.length + ' records')
            //await this.idb.insertBulk('jobs', additions, function () {console.log('done inserting') });
            await this.idb.insertBulk('jobs', additions, function () {
                //console.log('done inserting') 
            });
            this.logger.push('Insert Complete');
            this.logger.push('beginning update of ' + updates.length + ' records');
            await this.idb.updateBulk('jobs', updates, function () { 
                //console.log('done updating') 
            });
            this.logger.push('updated ' + updates.length + ' records');
            this.pauseSync = false;
            this.syncActive = false;


            // we need to compare
            // if job != in the jobFolder run the jobLoader for that job
            // if the job IS in the jobFolder, compare the updateDate prop

            // jobList.forEach(async (jobID: any) => {
            //     await this.checkJobInJobFolders(jobID);
            // })

            

            this.checkJobBulk(jobList);


        } catch (error) {
            console.error(error);
            this.pauseSync = false;
            this.syncActive = false;
        }


    }

    async checkJobBulk(jobsBulkArray: any): Promise<void> {
        console.log("checkJobInJobFolders => ", jobsBulkArray);
        try {
            const transaction = this.idb.db.transaction(['jobFolders'], 'readonly');
            const jobFoldersStore = transaction.objectStore('jobFolders');
            
            const notInFolderJobs: any[] = [];
            
            // Process each job ID in the bulk array
            for (const jobID of jobsBulkArray) {
                const jobFolderRequest = jobFoldersStore.get(jobID);
    
                jobFolderRequest.onsuccess = () => {
                    if (!jobFolderRequest.result) {
                        notInFolderJobs.push(jobID);
                    }
                };
    
                jobFolderRequest.onerror = () => {
                    console.error('Error retrieving job folder:', jobFolderRequest.error);
                };
            }
    
            // Wait for the transaction to complete
            transaction.oncomplete = () => {
                //console.log("notInFolderJobs => ", notInFolderJobs)
                if (notInFolderJobs.length > 0) {
                    let jobListFormat = {
                        "jobs" : [notInFolderJobs]
                    }
                    this.handleJobNotInFolder(jobListFormat);
                }
            };
    
            transaction.onerror = () => {
                console.error('Transaction error:', transaction.error);
            };
        } catch (error) {
            console.error('Error checking job in jobFolders:', error);
        }
    }
      
    async handleJobNotInFolder(jobsArray: any) {
        if(this.bulkAttempts === 1){
            console.log('Jobs to send to the bulk', jobsArray);
            this.dl.jobBulkLoader(jobsArray).then(res => {
                console.log('job bulk completed....')
                this.bulkAttempts = 0;
            })

        } else {
            console.log('job bulk is already running....')
        }
    }
    // async checkJobInJobFolders(jobID: any): Promise<void> {
    //     //console.log("checkJobInJobFolders => ", jobID);
    //     try {
    //         const transaction = this.idb.db.transaction(['jobFolders'], 'readonly');
    //         const jobFoldersStore = transaction.objectStore('jobFolders');
    
    //         const jobFolderRequest = jobFoldersStore.get(jobID);
    
    //         jobFolderRequest.onsuccess = () => {
    //             if (!jobFolderRequest.result) {
    //                 this.handleJobNotInFolder(jobID);
    //             }
    //         };
    
    //         jobFolderRequest.onerror = () => {
    //             console.error('Error retrieving job folder:', jobFolderRequest.error);
    //         };
    //     } catch (error) {
    //         console.error('Error checking job in jobFolders:', error);
    //     }
    // }
      
    // async handleJobNotInFolder(jobID: any) {
    //     console.log('Job', jobID, 'is not found in jobFolders. Performing jobLoader.');
    //     this.dl.jobLoader(jobID, true)
    // }
      


    async getLastUpdatedByPromise(): Promise<{ value: string, responseTime: number }> {
        return new Promise((resolve, reject) => {
            var startTime = new Date();
            var maxDate;
            var transaction = this.idb.db.transaction(["jobs"]);
            transaction.oncomplete = () => {
                var rt = (new Date().getTime() - startTime.getTime())
                if (!maxDate) maxDate = '1/1/2022';
                resolve({ value: maxDate, responseTime: rt })
            }
            transaction.onerror = () => reject({ value: '1/1/2022', responseTime: 0 });

            var objectStore = transaction.objectStore('jobs');
            var index = objectStore.index("updatedDate");
            var boundKeyRange = IDBKeyRange.lowerBound(new Date());
            index.openCursor(boundKeyRange, "prev").onsuccess = (event) => {
                var cursor = event.target.result;
                if (cursor) {
                    maxDate = cursor.key;
                    cursor.continue(new Date('1/1/2022'));
                }
            };
        }
        );

    }
    async getJobIDListByPromise(): Promise<number[]> {
        return new Promise((resolve, reject) => {
            var Jobs = [];
            var transaction = this.idb.db.transaction(["jobs"]);
            transaction.oncomplete = () => resolve(Jobs);
            transaction.onerror = () => reject(null);

            var request = transaction.objectStore('jobs').getAllKeys();
            request.onsuccess = (event) => Jobs = request.result
        });
    }

    async syncJobFolders() {

        return new Promise(async (resolve, reject) => {

            var jobs: number[] = await this.idb.getKeys('jobs')
            var jobFolders: number[] = await this.idb.getKeys('jobFolders')
            var updates = [];
            var inserts = [];
            this.JobsToInsert = inserts;
            this.JobsToUpdate = updates;
            
            inserts = jobs.filter(x => !jobFolders.includes(x));
            updates = jobs.filter(x => jobFolders.includes(x));

            this.JobsToInsert = inserts;
            this.JobsToUpdate = updates;

            this.initJobFolderSync()
            resolve({ inserts: inserts, updates: updates });
        })

    }

}
