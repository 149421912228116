export class Task{
    /* these 3 properties must be assigned by the user */
    url:string;
    method:string;
    payload:any;

    /* these are assigned by the service */
    queueID?:number;
    createDate?:Date;
    updatedDate?:Date;
    counterTries?:number;
    reportName?:string;
    taskName?:string;
    typeObject?:any;
   
    constructor(task:Task){        

        this.url = task.url ? task.url : '';
        this.method = task.method ? task.method : 'GET';
        this.payload = task.payload ? task.payload: null;
        this.createDate = new Date();
        this.queueID = new Date().getTime();
        this.counterTries = 0;    
    }
    
}
 

 